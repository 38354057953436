"use client";
import { useState } from "react";
import useUser from "@/lib/useUser";
import { Button, Flex, IconButton } from "@radix-ui/themes";
import { IconDownload } from "@tabler/icons-react";
import NewLogoPicker from "@/components/CustomComponents/NewLogoPicker";
import * as Dialog from "@/components/CustomComponents/Dialog"

interface Props {
  campaign: Campaign;
  tags: string[];
  types: string[];
  sortKey: string;
  sortDir: string;
}

export default function NewCreateReport({
  campaign,
  tags,
  types,
  sortKey,
  sortDir,
}: Props) {
  const [img, setImg] = useState(null as any);
  const { checkRoles } = useUser();
  const [loading, setLoading] = useState(false);

  console.log(img);
  const create = async (_: any) => {
    if (img) {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onloadend = async () => {
        const base64data = reader.result;
        await download(base64data);

        console.log(base64data);
      };
    } else {
      download(null);
    }
  };

  const download = async (base64data: any) => {
    setLoading(true);
    fetch(
      `/puppeteer/report/${campaign.shareCode}?domain=${window.location.host}&types=${types}&tags=${tags}&initialSortDir=${sortDir}&initialSortKey=${sortKey}`,
      {
        method: "POST",
        body: JSON.stringify({ image: base64data }),
        headers: { "Content-Type": "application/json" },
      },
    )
      .then((result) => {
        if (!result.ok) {
          throw Error(result.statusText);
        }

        // We are reading the *Content-Disposition* header for getting the original filename given from the server
        // const header = result.headers.get("Content-Disposition");
        // if(header) {
        //
        // }
        // const parts = header.split(";");
        // let filename = parts[1].split("=")[1].replaceAll('"', "");

        return result.blob();
      })
      // We use the download property for triggering the download of the file from our browser.
      // More information about the following code can be found here: https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch.
      // The filename from the first promise is used as name of the file.
      .then((blob) => {
        if (blob != null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          if (campaign.artist?.name) {
            a.download = `${campaign.artist.name}-${campaign.song?.name}.pdf`;
          } else {
            a.download = `${campaign.name}.pdf`;
          }
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        setLoading(false);
      })
      // The catch is getting called only for client-side errors.
      // For example the throw in the first then-promise, which is the error that came from the server.
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Dialog.Root>
    <Dialog.Trigger>
      <IconButton variant="ghost">
        <IconDownload className="icon"/>
      </IconButton>
    </Dialog.Trigger>
    <Dialog.Portal>
    <Dialog.Content style={{maxWidth: "500px"}}>
      <Dialog.Title style={{alignContent: "center"}}>Create Report</Dialog.Title>

      <Flex direction="column" gap="3" align="center" py="6">
        {checkRoles(["superadmin"]) && <NewLogoPicker setImage={setImg} />}
      </Flex>

      <Flex gap="3" mt="4" justify="center">
      <Button onClick={() => create(close)}>
          <Flex justify="center" gap="3">
            Create
            {loading && (
              <div className="loader ease-linear rounded-full border-gray-200 !h-6 !w-6 "></div>
            )}
          </Flex>
        </Button>
        <Dialog.Close>
          <Button variant="soft" color="gray">
            Cancel
          </Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
  );
}
// <Link href={`/report/${campaign.shareCode}`}>
//   <Button type="danger">Open report link </Button>
// </Link>
